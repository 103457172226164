.contact {
  display: flex;
  justify-content: center;
  padding: 150px 80px;
  text-align: center;
  color: inherit;
}
.contact .left,
.contact .right {
  height: 500px;
  width: 50%;
}
.contact .left {
  display: flex;
}
.contact .right {
  background-color: rgb(154, 154, 154);
}
.contact p {
  font-size: 1rem;
}
.contact p a {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.5s;
}

.contact p a:hover {
  color: var(--primary);
  transition: color 0.5s;
}

@media screen and (max-width: 1080px) {
  .contact {
    justify-content: center;
  }
  .contact p {
    /* text-align: left; */
  }
}
